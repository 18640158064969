* {
  padding: 0;
  margin: 0;
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.circle-container {
  height: 100;
  width: 100;
  transition: all 1s ease;
}


.gridDiv-canberra {
display: grid;
/* overflow: visible; */
/* background-image: url("canberra.png");
background-size: 75%;
background-position: center center;
background-repeat: no-repeat; */
height: 100vh;
grid-template-columns: 2fr 8rem 8rem 8rem 2fr;
grid-template-rows: 3rem 3rem 3rem repeat(11, 1fr) ;
/* grid-auto-rows: 1fr; */
grid-template-areas:
  "header header header header header"
  "subhead subhead subhead subhead subhead"
  ". slider slider slider ."
  ". . empty . ."
  ". . . gungah ."
  ". belcon . . ."
  ". . . innnor ."
  ". . empty-2 . ."
  ". molong .  innsou ."
  ". weston woden . ."
  ". . empty-3 . ."
  ". . tugger . ."
  ". . empty-4 . ."
}


.gridDiv-belconnen {
  display: grid;
  height: 100vh;
  grid-template-columns: 2fr repeat(8, 1.5fr) 2fr;
  grid-template-rows: 3rem 3rem 3rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "header header header header header header header header header header"
    "subhead subhead subhead subhead subhead subhead subhead subhead subhead subhead"
    ". . slider slider slider slider slider slider . ."
    /* ". . . . . empty . . . ." */
    ". . dun . fra . . . . . "
    ". . . cha fly spe . . . . "
    ". . mac lat mel eva mck gir . . "
    ". hol hig . flo . . law kal ."
    ". . . scu pag bel . . . ."
    ". . . haw wee maq . bru . ."
    ". . . . . coo ara . . ."
  }

.header-box {
  grid-column: header-start / header-end;
  grid-row: header-start / slider-end;
  background-color: antiquewhite;
}

.canberra-img {
  grid-column: 2 / 5;
  grid-row: 5 / 13;
  z-index: -1;
  height: 140%;
  place-self: center;
  overflow: hidden;
}

.belconnen-img {
  grid-column: 2 / 10;
  grid-row: 5 / 12;
  z-index: -1;
  width: 110%;
  place-self: center;
  overflow: hidden;
}

.empty-div {
  grid-area: empty;
  min-height: 150px;
}

.empty-div-2 {
  grid-area: empty-2;
  height: 150px;
}

.empty-div-3 {
  grid-area: empty-3;
  height: 150px;
}

.empty-div-4 {
  grid-area: empty-4;
  height: 150px;
}


/* Areas */


#GREATERGUNGAHLIN {
  grid-area: gungah;
}

#GREATERBELCONNEN {
  grid-area: belcon;
}

#INNERNORTH {
  grid-area: innnor;
}

#MOLONGLODISTRICT {
  grid-area: molong;
}

#INNERSOUTH {
  grid-area: innsou;
}

#WESTONCREEK {
  grid-area: weston;
}

#WODENCREEK {
  grid-area: woden;
}

#TUGGERANONGCREEK {
  grid-area: tugger;
}





/* Belconnen Suburbs */

#DUNLOP {
  grid-area: dun;
}

#FRASER {
  grid-area: fra;
}

#CHARNWOOD {
  grid-area: cha;
}

#FLYNN {
  grid-area: fly;
}

#SPENCE {
  grid-area: spe;
}

#MACGREGOR {
  grid-area: mac;
}

#LATHAM {
  grid-area: lat;
}

#MELBA {
  grid-area: mel;
}

#EVATT {
  grid-area: eva;
}

#MCKELLAR {
  grid-area: mck;
}

#GIRALANG {
  grid-area: gir;
}

#HOLT {
  grid-area: hol;
}

#HIGGINS {
  grid-area: hig;
}

#FLOREY {
  grid-area: flo;
}

#LAWSON {
  grid-area: law;
}

#KALEEN {
  grid-area: kal;
}

#SCULLIN {
  grid-area: scu;
}

#PAGE {
  grid-area: pag;
}

#BELCONNEN {
  grid-area: bel;
}

#HAWKER {
  grid-area: haw;
}

#WEETANGERA {
  grid-area: wee;
}

#MACQUARIE {
  grid-area: maq;
}

#BRUCE {
  grid-area: bru;
}

#COOK {
  grid-area: coo;
}

#ARANDA {
  grid-area: ara;
}






.gridDiv h1 {
  grid-area: header;
  justify-self: center;
}

.gridDiv h2 {
  grid-area: subhead;
  justify-self: center;
}

.gridDiv svg {
  justify-self: center;
  align-self: center;
}

.gridDiv div circle {
  transition: r 0.8s;
}

/* .gridDiv div svg {
  width: 100%;
  height: 100%;
} */

.gridSVG h5 {
  place-self: center;
  z-index: 10;
  color: green;
  display: none;
  background-color: antiquewhite;
  border-radius: 1rem;
  text-align: center;
  padding: 1rem;
  overflow: visible;
}

.gridSVG svg {
  place-self: center;
  z-index: 2;
  height: 100%;
  width: 100%;
  overflow: visible;
}

.gridSVG {
  display: grid;
  height: 100%;
  overflow: visible;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    "svg";
  justify-content: center;
  z-index: 8;
}

.suburbCircle {
  grid-area: svg;
  place-self: center;
  height: 100%;
}
.gridSVG:hover {
  fill: antiquewhite;
  /* display: block; */
}

.gridSVG:hover h5 {
  display: initial;
}

.suburbHeading {
  /* grid-area: svg; */
  place-self: center;
  text-decoration: none !important;
}

/* .Area {
  width: 100vw;
} */

/* .Area div {
  justify-content: space-around;
  align-items: center;
  height: 319px;
} */

.slider {
  grid-area: slider;
  justify-self: center;
}

.MuiSlider-sizeMedium {
  /* display: flex; */
  /* justify-content: space-around; */
  /* margin-left: 30vw;
  margin-right: 30vw; */
}